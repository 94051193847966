<template>
    <div class="app">

        <div class="text-center" style="text-align: center;">
            <!-- <img class="images" src="../assets/logo.png" alt=""   srcset="" height="5%" width="5%" />
     -->
        </div>

        <div class="root mt-5 ">
            <b-card class="text-center mb-2 " header="ປ້ອນຂໍ້ມູນຜູ້ໃຊ້ງານ ">

                <b-card-text>
                    <b-row>
                        <b-col>

                            <b-form-input class="p-3" id="input-large" size="lg" v-model="user.username"
                                :placeholder="$t('LoginPage.username')"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>

                            <b-form-input class="p-3" type="password" id="input-large" v-model="user.password" size="lg"
                                :placeholder="$t('LoginPage.password')"></b-form-input>
                        </b-col>
                    </b-row>
                    <br />
                    <b-button block variant="primary" v-on:click="onLogin()"> {{ $t('LoginPage.btnlogin') }} </b-button>
                </b-card-text>
                <b-alert :show="dismissCountDown" dismissible fade variant="warning">
                    {{ msg }}
                </b-alert>
            </b-card>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    props: {},
    name: "Login",
    data() {
        return {
            show: false,
            dismissSecs: 5,
            dismissCountDown: 0,
            msg: "",
            showDismissibleAlert: false,
            user: {
                username: "",
                password: "",
            },
        };
    },
    mounted() {

    },

    methods: {

        lang_en() {
            // i18n.locale = 'en'
            this.$i18n.locale = 'en'
        },
        lang_la() {
            //   i18n.locale = 'la'
            this.$i18n.locale = 'la'

        },
        onLogin() {
            //  console.log(this.$store)
            //  this.$router.push('/')
            //   this.$store.actions.commitSAuth()
            // this.$store.dispatch('setLogin');

            let login = [];

            let rep = null;
            let data = this.user;
            axios
                .post(this.$store.getters.getBaseUrl + "/login", data, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: 'Bearer anUrbLQdEVK7V/w3Mjkp28ZDJXgDH7SpKeqtKBjkqMnNJT4OQ?ukI8w7M5/HmxsONU78KEg4p=!5r0WOf=5/zBa9h/PTI42lb5JEhR855=LTAt!!AeJBFkmFtrpOwS7KhMuk!=B=8Rn=aqz6U59ZoPATYEKFkaZ-C/4ZRUXjUfb4DA0-69KsueEHkI/RW8mD2lLudpbe5JZpti=uQmQe5Nq2G6pP9//DX9hGm3yFpVMInuh-O50nbYdMej1miJTs',
                        "Accept-Encoding": "identity"
                    }
                })
                .then((response) => {
                    //     console.log("response: ", response.data);
                    rep = response.data;
                    // do something about response
                    //   this.$store.commit("setCurrentUser",username);
                    //   this.$store.commit("setToken",response.data['TOKEN']);
                    if (rep.code == 1) {
                        login = rep.rows[0];
                        /*  
          login={
                    Auth_Login: true,
                    Username: "",
                    Token: "",
                    Level: "",
                  }
            */


                        localStorage.removeItem("Login-qr");
                        localStorage.setItem("Login-qr", JSON.stringify(login));
                        this.$store.commit("setLogin", true);

                        //  console.log(this.$store.state.token);
                        //  this.$router.push('/Home')
                        //this.$router.push("/");

                        //this.$router.replace("/");
                        this.$router.push('/qrscanqr')
                    } else {
                        this.$bvToast.toast(rep.msg, {
                            title: `Error:`,
                            toaster: 'b-toaster-top-right',
                            solid: true,
                            appendToast: true
                        })
                    }
                })
                .catch((err) => {
                    console.error(err);
                    //alert(err)
                    // countDownChanged;
                    this.msg = err;
                    this.dismissCountDown = 5;
                });

            //let url= this.$store.getters.getBaseUrl()
        },
    },
};
</script>

<style scoped>
label {
    font-family: "Noto Sans Lao Regular";
}

.text-md-center {
    padding: 10px;
}

.images {
    margin-top: 60px;
}

.font {
    font-family: "Noto Sans Lao Regular";
}

.root {
    width: 500px;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px;
    margin-top: 30px;
    border-radius: 20px;
}

.wave {
    position: fixed;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
}

input {
    border: none;
    outline: none;
    border-bottom: 1px solid #ddd;
    font-size: 1em;
    padding: 10px 0;
    margin: 25px 0 5px 0;
    width: 100%;
}

button {
    background-color: #3498db;
    padding: 10px 20px;
    margin-top: 10px;
    border: none;
    color: white;
}
</style>
